import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Avatar from '../../../components/Avatar';
import CommonHeaderRight from './CommonHeaderRight';
import Icon from '../../../components/icon/Icon';

import { Trans, useTranslation } from 'react-i18next';

const LoggedInDefaultHeader = () => {
	return (
		<Header hasLeftMobileMenu={false}>
			<HeaderLeft>
				<div style={{'textAlign': 'center'}}> 
				{/* ToDo */}
					{/* <Trans>trans_beta_testing</Trans> */}
				</div>
			</HeaderLeft>
			
			<CommonHeaderRight />
		</Header>
	);
};

export default LoggedInDefaultHeader;
