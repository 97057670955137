import React, { lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import AuthContext from '../../contexts/authContext';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const { user, setUser } = useContext(AuthContext);

	return (
		<Routes>
			{contents.map(({ path, isPrivate, ...rest }) => {
				if (isPrivate && !user) {
					return <Route key={path} path={`/${path}`} element={<PAGE_404 />} />; // Show 404 if not logged in
				}

				// eslint-disable-next-line react/jsx-props-no-spreading
				return <Route 
					key={path} 
					path={`/${path}`}
					{...rest}
				 />
			})}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;