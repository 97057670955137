import React, { FC, useState } from 'react';
import Button, { IButtonProps } from '../../components/bootstrap/Button';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Spinner from '../../components/bootstrap/Spinner';
import Icon from '../../components/icon/Icon';
import LANG, { getLangWithKey, ILang } from '../../lang';
import useDarkMode from '../../hooks/useDarkMode';
import { useTranslation, Trans } from 'react-i18next';
import showNotification from '../../components/extras/showNotification';

interface ILangSelectorProps {}
const LangSelector: FC<ILangSelectorProps> = ({}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { i18n, t } = useTranslation();
	const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);


	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then();

		let langChangedToTxt = t('Language_changed_to_x', {
			p1: getLangWithKey(lng)?.text,
		});

		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{langChangedToTxt}</span>
			</span>,
			<Trans>You_updated_language</Trans>,
		);
	};

	return (
		<div className='col-auto'>
			<Dropdown isOpen={isDropdownMenuOpen} setIsOpen={setIsDropdownMenuOpen}>
				<DropdownToggle hasIcon={false}>
					{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
					'undefined' ? (
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							className='btn-only-icon'
							aria-label='Change language'
							data-tour='lang-selector'>
							<Spinner isSmall inButton='onlyIcon' isGrow />
						</Button>
					) : (
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={getLangWithKey(i18n.language as ILang['key']['lng'])?.icon}
							aria-label='Change language'
							data-tour='lang-selector'
						/>
					)}
				</DropdownToggle>
				<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
					{Object.keys(LANG).map((i) => (
						<DropdownItem key={LANG[i].lng}>
							<Button icon={LANG[i].icon} 
								onClick={() => {
										setIsDropdownMenuOpen(false);
										changeLanguage(LANG[i].lng)
									}
								}>
								{LANG[i].text}
							</Button>
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default LangSelector;
