import React, {
	createContext,
	FC,
	ReactNode,
	useEffect,
	useState,
	useMemo,
	useCallback,
  } from 'react';
  import PropTypes from 'prop-types';
  import { User } from '../common/data/models/user';


  import { useIntercom } from 'react-use-intercom';
import { C } from '../helpers/C';

  
  // Types
  export interface IAuthContextProps {
	user: User | null;
	setUser: (user: User | null) => void;
	updateUser: (updates: Partial<User>) => void;
	isLoading: boolean;
  }
  
  interface IAuthContextProviderProps {
	children: ReactNode;
  }
  
  // Context
  const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);
  
  // Helper function to get the initial user value from localStorage
  const getInitialUser = (): User | null => {
	const loggedInUser = localStorage.getItem('logged_in_user');
	return loggedInUser ? JSON.parse(loggedInUser) : null;
  };
  
  export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
	children,
  }) => {
	const [user, setUser] = useState<User | null>(getInitialUser());

	const [isLoading, setIsLoading] = useState(true);

	const { boot, shutdown } = useIntercom();

  
	// Set loading to false once the component is mounted
	useEffect(() => {
	  setIsLoading(false);
	}, []);


  
	// Update localStorage based on the user's state
	useEffect(() => {
	  if (user) {
		localStorage.setItem('logged_in_user', JSON.stringify(user));
	  } else {
		localStorage.removeItem('logged_in_user');
	  }

	shutdown();
	if (user) {
		boot({
			name: user.displayName || '',
			email: user.email || '',
			userId: user.uid,
		}); 
	} else {
		const randNum = Math.floor(Math.random() * 100);
		const randName = C.RANDOM_NAMES_ARRAY[Math.floor(Math.random() * C.RANDOM_NAMES_ARRAY.length)] + randNum;

		const name = localStorage.getItem('intercom_name') || randName;
		localStorage.setItem('intercom_name', name);

		boot({
			name: name,
		});
	}


	}, [user]);
  
	// Method to update user with partial updates
	const updateUser = useCallback(
	  (updates: Partial<User>) => {
		setUser((currentUser) => {
			if (currentUser === null){
				localStorage.removeItem('logged_in_user');
				return null;
			}; 
		  const updatedUser = { ...currentUser, ...updates };
		  localStorage.setItem('logged_in_user', JSON.stringify(updatedUser));
		  return updatedUser;
		});
	  },
	  [setUser]
	);
  
	const value = useMemo(
	  () => ({
		user,
		setUser,
		updateUser,
		isLoading,
	  }),
	  [user, updateUser, isLoading]
	);
  
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  };
  
  AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
  };
  
  export default AuthContext;
  
  