export const landingPageTopMenu = {
	intro: { 
		id: 'intro', 
		text: 'trans_Intro', 
		path: '#intro', 
		subMenu: null 
	},
	try_it: {
		id: 'try_it',
		text: 'trans_Try it',
		path: '#try_it',
		subMenu: null,
	},
	
	features: { 
		id: 'features',
		text: 'trans_Features',
		path: '#features',
		subMenu: null, 
	},

	pricing: {
		id: 'pricing',
		text: 'trans_Pricing',
		path: '#pricing',
		subMenu: null,
	},

	webapp: {
		id: 'webapp',
		text: 'trans_Web App',
		path: '#webapp',
		subMenu: null,
	},

	androidapp: {
		id: 'androidapp',
		text: 'trans_Android App',
		path: '#androidapp',
		subMenu: null,
	},


	contact: {
		id: 'contact',
		text: 'trans_Contact',
		path: '#contact',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const mainMenu = {
	data: {
		id: 'data',
		text: 'Data',
	},
	inventories: {
		id: 'inventories',
		text: 'Inventories',
		path: 'inventories',
		icon: 'Inventory2',
		subMenu: null,
	},
	products: {
		id: 'products',
		text: 'Products',
		path: 'products',
		icon: 'List',
		subMenu: null,
	},
	transactions: {
		id: 'transactions',
		text: 'Transactions',
		path: 'transactions',
		icon: 'CompareArrows',
		subMenu: null,
	},

};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'trans_profile',
	},
	licenses: {
		id: 'licenses',
		text: "trans_buy_licenses",
		path: 'licenses',
		icon: 'Stars',
	},

	myprofile: {
		id: 'myprofile',
		text: "trans_my_profile",
		path: 'profile',
		icon: 'Person',
	},


	// login: {
	// 	id: 'login',
	// 	text: 'Login',
	// 	path: 'auth-pages/login',
	// 	icon: 'Login',
	// },
};

export const miscMenu = {
	misc: {
		id: 'misc',
		text: 'Misc',
	},

	fields: {
		id: 'fields',
		text: 'trans_fields',
		path: 'misc/fields',
		icon: 'Menu',
		isPrivate: true,
		subMenu: {
			locations: {
				id: 'locations',
				text: 'trans_locations',
				path: 'fields/locations',
				icon: 'LocationOn',
			},
			custom_fields: {
				id: 'custom_fields',
				text: 'trans_custom_fields',
				path: 'fields/custom_fields',
				icon: 'DashboardCustomize',
			},
			categories: {
				id: 'categories',
				text: 'trans_categories',
				path: 'fields/categories',
				icon: 'Category',
			},
			mus: {
				id: 'mus',
				text: 'trans_measurement_units',
				path: 'fields/mus',
				icon: 'Workspaces',
			},
			tags: {
				id: 'tags',
				text: 'trans_tags',
				path: 'fields/tags',
				icon: 'Tag',
			},
		}
	},

	import: {
		id: 'import',
		text: 'trans_send_xls_to_mobile',
		path: 'misc/import',
		icon: 'FileDownload',
	},

	// merge: {
	// 	id: 'merge',
	// 	text: 'Merge_Excel',
	// 	path: 'misc/merge',
	// 	icon: 'MergeType',
	// },

	support: {
		id: 'support',
		text: 'Support',
		path: 'support',
		icon: 'HelpOutline',
	},
};

export const publicPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'page404',
		text: 'Page404',
		path: 'page404',
		icon: 'PersonAdd',
	},
	landingPage: {
		id: 'landingpage',
		text: 'Landing Page',
		icon: 'Extension',
		path: 'landingpage',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
