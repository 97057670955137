import React, { useState, useRef, useEffect } from 'react';


import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';


import Button from '../../components/bootstrap/Button';

import showNotification from '../../components/extras/showNotification';
import Spinner from '../../components/bootstrap/Spinner';


import { Trans, useTranslation } from 'react-i18next';



interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  titleText?: string;
  descriptionText?: string;
  greenButtonText?: string; 
  redButtonText?: string; 
  greyButtonText?: string; 
  greenButtonClicked?: (() => void); 
  redButtonClicked?: (() => void); 
  greyButtonClicked?: (() => void);
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onClose
      , titleText, descriptionText, greenButtonText, redButtonText, greyButtonText, greenButtonClicked, redButtonClicked, greyButtonClicked }) => {

  const { t } = useTranslation();

  const [isGreenButtonLoading, setIsGreenButtonLoading] = useState(false);
  const [isRedButtonLoading, setIsRedButtonLoading] = useState(false);
  const [isGreyButtonLoading, setIsGreyButtonLoading] = useState(false);
  
  const handleGreenButtonClicked = async () => {
    setIsGreenButtonLoading(true);
    if (greenButtonClicked) await greenButtonClicked();
    setIsGreenButtonLoading(false);
  };

  const handleRedButtonClicked = async () => {
    setIsRedButtonLoading(true);
    if (redButtonClicked) await redButtonClicked();
    setIsRedButtonLoading(false);
  };

  const handleGreyButtonClicked = async () => {
    setIsGreyButtonLoading(true);
    if (greyButtonClicked) await greyButtonClicked();
    setIsGreyButtonLoading(false);
  };
  
    return ( 
      <Modal
        id="add-product-modal"
        isScrollable
        size='sm'
        isStaticBackdrop={false}
        setIsOpen={onClose}
        titleId="example-title"
        isOpen={isOpen}
        isAnimation={false}
      >

			<ModalHeader >

        {titleText &&
          <ModalTitle id="example-title">
            {titleText}
          </ModalTitle>
        }
      
			</ModalHeader>

      <ModalBody>

      {descriptionText &&
        <div>
          {descriptionText}
        </div>
      }

			</ModalBody>
      
			<ModalFooter>

        {greyButtonText &&
          <Button 
           color='light'
           onClick={handleGreyButtonClicked}
          >
            {isGreyButtonLoading && <Spinner isSmall inButton />}
            {greyButtonText}
          </Button>
        }

        {redButtonText &&
          <Button
            color="danger"
            onClick={handleRedButtonClicked}
            isDisable={isRedButtonLoading}
          >
            {isRedButtonLoading && <Spinner isSmall inButton />}
            {redButtonText}
          </Button>
        }

          
      {greenButtonText &&

        <Button
          color="success"
          onClick={handleGreenButtonClicked}
          isDisable={isGreenButtonLoading}
          >
          {isGreenButtonLoading && <Spinner isSmall inButton />}
          {greenButtonText}
        </Button>

      }

         
			</ModalFooter>

		</Modal>
    );
    

  };
  
  export default ConfirmModal;
  