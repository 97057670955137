import React, { useState, useContext, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { publicPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';
import { Trans } from 'react-i18next';

import {maleAvatars, femaleAvatars} from '../../common/data/AvatarImages';

import { auth } from '../../helpers/firebase';
import { endpoints, sendRequest } from '../../helpers/api';

const User = () => {
	const { user, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation();

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					{user?.photoURL != null && (
						<img
							srcSet={user.photoURL}
							src={user.photoURL}
							alt='Avatar'
							width={128}
							height={128}
						/>
					)}

					{user?.photoURL == null && (
						<img
							srcSet={maleAvatars[0].srcSet}
							src={maleAvatars[0].src}
							alt='Avatar'
							width={128}
							height={128}
						/>
					)}
				</div>

				<div className='user-info'>
					{user && (
						<>
						<div className='user-name'>
							{user.displayName != null ? user.displayName : user.email}
						</div>
						{/* <div className='user-sub-title'>{user.email}</div> */}
						</>
					)}
					{!user  &&  <div style={{fontSize: '12px'}}><Trans>trans_not_authenticated</Trans></div>}
				</div>
				
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/profile`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('trans_my_profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div> */}
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={async () => {
								if(user?.sessionId){
									await sendRequest(endpoints.logout(user?.sessionId));
								}
								
								await auth.signOut();
								localStorage.removeItem('selectedidInventory');
								if (setUser) {
									setUser(null);
								}
								navigate(`../${publicPagesMenu.login.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>

								{user &&
									<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>
											{
												t('trans_logout') as ReactNode
											}
										</span>
									</span>
								}

								{!user &&
									<span className='navigation-link-info'>
									<Icon icon='Login' className='navigation-icon' />
										<span className='navigation-text'>
											{
												t('trans_login') as ReactNode
											}
										</span>
									</span>
								}
								
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
