import { IBaseModel } from "../common/data/models/IBaseModel";
import { SystemField } from "../common/data/models/systemField";
import i18n from "../i18n";

import { Trans, useTranslation } from 'react-i18next';


export const getAllSystemFields = () => [
    new SystemField("sys_field_1_sku", i18n.t("trans_sku")),
    new SystemField("sys_field_2_product_name", i18n.t("trans_product_name")),
    new SystemField("sys_field_3_barcode", i18n.t("trans_barcode")),
    new SystemField("sys_field_4_uom", i18n.t("trans_um")),
    new SystemField("sys_field_5_stock", i18n.t("trans_stock")),
    new SystemField("sys_field_6_variation", i18n.t("trans_variation")),
    new SystemField("sys_field_7_category", i18n.t("trans_category")),
    new SystemField("sys_field_8_book_quantity", i18n.t("trans_book_quantity")),
];

export const getSystemFieldsImportTS = () => [
    getAllSystemFields()[0],
    getAllSystemFields()[1],
    getAllSystemFields()[2],
    getAllSystemFields()[3],
    getAllSystemFields()[6],
    getAllSystemFields()[7],
];

export const getSystemFieldsImportSM = () => [
    getAllSystemFields()[0],
    getAllSystemFields()[1],
    getAllSystemFields()[2],
    getAllSystemFields()[3],
    getAllSystemFields()[6],
];


enum ProductFieldType {
    // Product Custom Fields 
    TEXT = 1,
    NUMBER = 2,
    IMAGE = 3,
    NFC = 4,
    DATE = 1005,
    YES_OR_NO = 1006,
    LOCATION = 1007,
    MIN_STOCK = 1008,
    BARCODE = 1009,
    DROPDOWN_LIST = 1010,

    // Special Expiration Date Fields
    EXPIRATION_USEFUL_LIFE = 3001,
    EXPIRATION_TYPE = 3002,
    EXPIRATION_ALERT_PERCENTAGE = 3003,
    EXPIRATION_ALERT_DAYS = 3004,

    // System Product Fields
    SYSTEM_SKU = 5001,
    SYSTEM_BARCODE = 5002,
    SYSTEM_NAME = 5003,
    SYSTEM_CATEGORY = 5004,
    SYSTEM_MU = 5005,
    SYSTEM_BATCH_MU = 5006,
    SYSTEM_FACTOR = 5007
}

enum EntryFieldType {
    // Entry Custom Fields
    TEXT = 1,
    NUMBER = 2,
    PRICE = 101,
    DATE = 2003,
    YES_OR_NO = 2004,
    IMAGES = 2005,
    DROPDOWN_LIST = 2006,
    BARCODE = 2007,

    // Special Expiration Date Fields
    EXPIRATION_DATE = 4001,

    // System Entry Fields
    SYSTEM_LOCATION = 5101,
}



const RANDOM_NAMES_ARRAY: string[] = [
    "Allan", "Alsop", "Anderson", "Arnold", "Avery", "Bailey", "Baker", "Ball", "Bell", "Berry",
    "Black", "Blake", "Bond", "Bower", "Brown", "Buckland", "Burgess", "Butler", "Cameron", "Campbell",
    "Carr", "Chapman", "Churchill", "Clark", "Clarkson", "Coleman", "Cornish", "Davidson", "Davies",
    "Dickens", "Dowd", "Duncan", "Dyer", "Edmunds", "Ellison", "Ferguson", "Fisher", "Forsyth", "Fraser",
    "Gibson", "Gill", "Glover", "Graham", "Grant", "Gray", "Greene", "Hamilton", "Hardacre", "Harris", "Hart",
    "Hemmings", "Henderson", "Hill", "Hodges", "Howard", "Hudson", "Hughes", "Hunter", "Ince", "Jackson",
    "James", "Johnston", "Jones", "Kelly", "Kerr", "King", "Knox", "Lambert", "Langdon", "Lawrence", "Lee",
    "Lewis", "Lyman", "MacDonald", "Mackay", "Mackenzie", "MacLeod", "Manning", "Marshall", "Martin", "Mathis",
    "May", "McDonald", "McLean", "McGrath", "Metcalfe", "Miller", "Mills", "Mitchell", "Morgan", "Morrison",
    "Murray", "Nash", "Newman", "Nolan", "North", "Ogden", "Oliver", "Paige", "Parr", "Parsons", "Paterson",
    "Payne", "Peake", "Peters", "Piper", "Poole", "Powell", "Pullman", "Quinn", "Rampling", "Randall", "Rees",
    "Reid", "Roberts", "Robertson", "Ross", "Russell", "Rutherford", "Sanderson", "Scott", "Sharp", "Short",
    "Simpson", "Skinner", "Slater", "Smith", "Springer", "Stewart", "Sutherland", "Taylor", "Terry", "Thomson",
    "Tucker", "Turner", "Underwood", "Vance", "Vaughan", "Walker", "Wallace", "Walsh", "Watson", "Welch",
    "White", "Wilkins", "Wilson", "Wright", "Young"
  ];

export const C = {
    getAllSystemFields,
    getSystemFieldsImportTS,
    getSystemFieldsImportSM,
    RANDOM_NAMES_ARRAY,
    ProductFieldType,
    EntryFieldType,
}


  