// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import showNotification from '../components/extras/showNotification';

import AuthContext from '../contexts/authContext';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FB_API_KEY,
	authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);



export const getIdToken = async (user:User, forceRefresh: boolean = false): Promise<string | null> => {
    if (user) {
        try {
            return await user.getIdToken(forceRefresh);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    return null;
};


auth.onIdTokenChanged(async (user) => {
    if (user) {
        const token = await user.getIdToken();
        
        const loggedInUserStr = localStorage.getItem('logged_in_user');
        const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr) : null;
        if(loggedInUser){
            loggedInUser.authToken = token;
            localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
        }
    } else {
        localStorage.removeItem('token');
    }
}); 


export const refreshFirebaseToken = async () => {
    console.log("refreshFirebaseToken");

    const user = auth.currentUser;
    console.log(user);
    if (user) {
        const token = await getIdToken(user, true);
        console.log("Refreshed token: " + token);
        if (token) {
            const loggedInUserStr = localStorage.getItem('logged_in_user');
		    const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr) : null;
            loggedInUser.authToken = token;
            localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
        }
    } else {
        console.log("No user found");
        // redirect to /login
        //window.location.href = "/login";
    }
}