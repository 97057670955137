export class User {
	
	public uid: string;

	public displayName: string | null;

	public email: string | null;

	public photoURL: string | null;

	public authToken: string;

	public sessionId: string | undefined;
	

	constructor(uid:string, displayName: string|null, email: string|null, photoURL: string|null, authToken: string) {
		this.uid = uid;
		this.displayName = displayName;
		this.email = email;
		this.photoURL = photoURL;
		this.authToken = authToken;
	}
}