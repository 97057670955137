import React, { SVGProps } from 'react';

const SvgCustomRomania = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 512 512'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='svg-icon'
        {...props}>
        <mask
            id='custom-romania_svg__a'
            style={{
                maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={0}
            y={0}
            width={512}
            height={512}>
            <path
                d='M400 0H112C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0Z'
                fill='#f2f2f2' // Neutral mask color
            />
        </mask>
        <g mask='url(#custom-romania_svg__a)'>
            <path
                d='M170.67 0C152.263 0 136 25.92 136 57.867v396.267C136 486.08 152.263 512 170.67 512H341.33C359.737 512 376 486.08 376 454.133V57.867C376 25.92 359.737 0 341.33 0H170.67Z'
                fill='#FFD800' // Yellow
            />
            <path
                d='M0 0h170.67v512H0V0Z'
                fill='#002B7F' // Blue
            />
            <path
                d='M341.33 0H512v512H341.33V0Z'
                fill='#F31830' // Red
            />
        </g>
    </svg>
);

export default SvgCustomRomania;
