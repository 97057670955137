import React, { SVGProps } from 'react';

const SvgCustomPortugal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon"
    {...props}
  >
    {/* Adjusted rectangles to have rounded corners */}
    <rect
      x="0"
      y="0"
      width="204.8"
      height="512"
      fill="#006600"
      rx="20" // Adjust this value to control the roundness of the corners
      ry="20"
    />
    <rect
      x="204.8"
      y="0"
      width="307.2"
      height="512"
      fill="#FF0000"
      rx="20" // Same roundness for consistent appearance
      ry="20"
    />
    <circle
      cx="153.6"
      cy="256"
      r="102.4"
      fill="#FFD700"
    />
    <path
      d="M153.6 204.8a51.2 51.2 0 1 0 0 102.4 51.2 51.2 0 0 0 0-102.4zm0 17.067a34.133 34.133 0 1 1 0 68.267 34.133 34.133 0 0 1 0-68.267z"
      fill="#006600"
    />
    <path
      d="M146.133 256h15.734v51.2h-15.734v-51.2zm0-17.067h15.734v-15.733h-15.734v15.733z"
      fill="#FFD700"
    />
  </svg>
);

export default SvgCustomPortugal;
