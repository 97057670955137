import React, { lazy } from 'react';
import {
	dashboardPagesMenu,
	publicPagesMenu,
	mainMenu,
	pageLayoutTypesPagesMenu,
	miscMenu,
	profileMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/products/ProductsPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const MAIN_PAGES = {
	INVENTORIES: lazy(() => import('../pages/presentation/inventories/InventoriesPage')),
	PRODUCTS: lazy(() => import('../pages/presentation/products/ProductsPage')),
	TRANSACTIONS: lazy(() => import('../pages/presentation/transactions/TransactionsPage')),
	IMPORT: lazy(() => import('../pages/presentation/misc/ImportPage')),
	SUPPORT: lazy(() => import('../pages/presentation/misc/SupportPage')),
	MERGE: lazy(() => import('../pages/presentation/misc/MergePage')),

	LICENSES: lazy(() => import('../pages/presentation/buy_licenses/LicensesPage')),

	LANDINGPAGE: lazy(() => import('../pages/presentation/landingpage/LandingPage')),

	MYPROFILE: lazy(() => import('../pages/presentation/profile/MyProfilePage')), 

	LOCATIONS: lazy(() => import('../pages/presentation/fields/locations/LocationsPage')), 
	CUSTOM_FIELDS: lazy(() => import('../pages/presentation/fields/custom_fields/CustomFieldsPage')), 
	CATEGORIES: lazy(() => import('../pages/presentation/fields/categories/CategoriesPage')), 
	MUS: lazy(() => import('../pages/presentation/fields/mus/MusPage')), 
	TAGS: lazy(() => import('../pages/presentation/fields/tags/TagsPage')), 

}; 

const presentation = [
	/**
	 * Landing
	 */
	{
		path: mainMenu.inventories.path,
		element: <MAIN_PAGES.INVENTORIES />,
	},

	{
		path: mainMenu.products.path,
		element: <MAIN_PAGES.PRODUCTS />,
	},

	{
		path: `${mainMenu.products.path}/:idInventory`,
		element: <MAIN_PAGES.PRODUCTS />,
	},

	{
		path: `${mainMenu.transactions.path}`,
		element: <MAIN_PAGES.TRANSACTIONS />,
	},


	{
		path: `${mainMenu.transactions.path}/:idInventory`,
		element: <MAIN_PAGES.TRANSACTIONS />,
	},

	{
		path: `${mainMenu.transactions.path}/:idInventory/:idProduct`,
		element: <MAIN_PAGES.TRANSACTIONS />,
	},

	{
		path: profileMenu.licenses.path,
		element: <MAIN_PAGES.LICENSES />,
	},

	{
		path: profileMenu.myprofile.path,
		element: <MAIN_PAGES.MYPROFILE />,
	},

	{
		path: miscMenu.import.path,
		element: <MAIN_PAGES.IMPORT />,
	},

	{
		path: miscMenu.support.path,
		element: <MAIN_PAGES.SUPPORT />,
	},

	{
		path: "misc/merge",
		element: <MAIN_PAGES.MERGE />,
	},

	{
		path: publicPagesMenu.landingPage.path,
		element: <MAIN_PAGES.LANDINGPAGE />,
	},

	{
		path: publicPagesMenu.landingPage.path,
		element: <MAIN_PAGES.LANDINGPAGE />,
	},


	{
		path: miscMenu.fields.subMenu.locations.path,
		element: <MAIN_PAGES.LOCATIONS />,
		isPrivate: true,
	},

	{
		path: `${miscMenu.fields.subMenu.locations.path}/:idInventory`,
		element: <MAIN_PAGES.LOCATIONS />,
		isPrivate: true,
	},

	{
		path: miscMenu.fields.subMenu.categories.path,
		element: <MAIN_PAGES.CATEGORIES />,
		isPrivate: true,
	},

	{
		path: `${miscMenu.fields.subMenu.categories.path}/:idInventory`,
		element: <MAIN_PAGES.CATEGORIES />,
		isPrivate: true,
	},

	{
		path: miscMenu.fields.subMenu.tags.path,
		element: <MAIN_PAGES.TAGS />,
		isPrivate: true,
	},

	{
		path: `${miscMenu.fields.subMenu.tags.path}/:idInventory`,
		element: <MAIN_PAGES.TAGS />,
		isPrivate: true,
	},

	{
		path: miscMenu.fields.subMenu.custom_fields.path,
		element: <MAIN_PAGES.CUSTOM_FIELDS />,
		isPrivate: true,
	},

	{
		path: `${miscMenu.fields.subMenu.custom_fields.path}/:idInventory`,
		element: <MAIN_PAGES.CUSTOM_FIELDS />,
		isPrivate: true,
	},


	{
		path: miscMenu.fields.subMenu.mus.path,
		element: <MAIN_PAGES.MUS />,
		isPrivate: true,
	},

	{
		path: `${miscMenu.fields.subMenu.mus.path}/:idInventory`,
		element: <MAIN_PAGES.MUS />,
		isPrivate: true,
	},


	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: publicPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: publicPagesMenu.login.path,
		element: <Login />,
	},
	{ 
		path: publicPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/** 
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;
