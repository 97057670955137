import React, { FC, ReactNode, useContext, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import Avatar from '../../../components/Avatar';
import AuthContext from '../../../contexts/authContext';
import LangSelector from '../../_common/LangSelector';
import { profileMenu, publicPagesMenu } from '../../../menu';
import {maleAvatars, femaleAvatars} from '../../../common/data/AvatarImages';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import UserInfoContext from '../../../contexts/userInfoContext';

 
interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { user } = useContext(AuthContext);
	const { userInfo, fetchUserInfo, isLoading} = useContext(UserInfoContext);

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();
	const navigate = useNavigate();

	// const changeLanguage = (lng: ILang['key']['lng']) => {
	// 	i18n.changeLanguage(lng).then();
	// 	showNotification(
	// 		<span className='d-flex align-items-center'>
	// 			<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
	// 			<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
	// 		</span>,
	// 		'You updated the language of the site. (Only "Aside" was prepared as an example.)',
	// 	);
	// };

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	const { setIsOpen } = useTour();

	let licenseButtonText = "";
	let licensePopoverText = "";
	if(userInfo?.configs?.sync){
		licenseButtonText = t('trans_License') + ": " + "SYNC";
		licensePopoverText = t('trans_sync_benefits_popover');
	} else if(userInfo?.configs?.premium){
		licenseButtonText = t('trans_License') + ": " + "Premium";
		licensePopoverText = t('trans_premium_benefits_popover');
	} else {
		licenseButtonText = t('trans_no_license');
		licensePopoverText = t('trans_no_license_popover');
	}

	const isLandingPage = location.pathname === '/landingpage';

	

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{!isLandingPage && (
					<div className='col-auto d-flex flex-column justify-content-center'>
						<Popovers trigger='hover' desc={licensePopoverText}>
							<Button onClick={() => {
								navigate(`../${profileMenu.licenses.path}`);
							}}>
								{licenseButtonText}
							</Button>
						</Popovers>
					</div>
				)}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('trans_dark_light_mode')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div> */}

				<LangSelector />

				{/*	Notifications */}
				{/* <div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div> */}

				<div className='col d-flex align-items-center'
					role='presentation'
					style={{
						cursor: 'pointer',
					}}
					onClick={() => { 
						if(user != null){
							navigate(`../${profileMenu.myprofile.path}`);
						} else {
							navigate(`../${publicPagesMenu.login.path}`);
						}
					}}
				> 
					<div>
						{user && (
							<>
							<div className='fw-bold fs-6 mb-0'>
								{user.displayName != null ? user.displayName : user.email}
							</div>
							<div className='text-muted'>
								<small>{user.email}</small>
							</div>
							</>
						)}
						{!user && <div style={{fontSize: '12px'}}><Trans>trans_not_authenticated</Trans></div>}
					</div>

  
					<div className='ml-3' style={{marginLeft:"8px"}}>
						{user?.photoURL != null && (
							<Avatar
								srcSet={user.photoURL}
								src={user.photoURL}
								size={48}
								color='primary'
							/>
						)}

						{user?.photoURL == null && (
							<img
								srcSet={maleAvatars[0].srcSet}
								src={maleAvatars[0].src}
								alt='Avatar'
								width={48}
								height={48}
							/>
						)}
					</div>
				</div>

				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
