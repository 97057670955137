import { Expose } from 'class-transformer';
import { IBaseModel } from './IBaseModel';

export class SystemField implements IBaseModel {
	@Expose()
	id: string;

	@Expose()
	name: string;

    constructor(
        id: string,
        name: string,
    ) {
        this.id = id;
        this.name = name;
    }

    getIdItem(): string {
        return this.id;
    }

    getIdItemFb(): string {
        return "";
    }

    getName(): string {
        return this.name;
    }

}