import React, { ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, mainMenu, miscMenu, profileMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import { Trans } from 'react-i18next';


let supportsPWA = false;
let promptInstall:any = null;

const initPWAInstall = () => {
  const handler = (e:any) => {
    e.preventDefault();
    console.log("PWA installation prompt is ready.");
    supportsPWA = true;
    promptInstall = e;
  };

  window.addEventListener('beforeinstallprompt', handler);

  return () => {
    window.removeEventListener('beforeinstallprompt', handler);
  };
};

const showPWAInstallPrompt = () => {
  if (promptInstall) {
    promptInstall.prompt();
  } else if (!supportsPWA) {
    console.log("PWA installation is not supported or prompt is not ready.");
  } else {
    console.log("PWA installation prompt is not available.");
  }
};

initPWAInstall();



const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const navigate = useNavigate();

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation();

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

	

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={mainMenu} id='aside-main-menu' />

				<NavigationLine />

				<Navigation menu={profileMenu} id='aside-main-menu' />

				<NavigationLine />

				<Navigation menu={miscMenu} id='aside-main-menu' />

				{/* <Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				<NavigationLine /> */}
				{!doc && false && (
					<>
						<Navigation menu={mainMenu} id='aside-main-menu' />

						<NavigationLine />
					
					</>
				)}
				{asideStatus && doc && <div className='p-4'><Trans>trans_support</Trans></div>}
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {

								openInNewTab(
									'https://play.google.com/store/apps/details?id=ro.bino.inventory',
								);

								// localStorage.setItem('facit_asideDocStatus', String(!doc));
								// setDoc(!doc);
							}}
							data-tour='documentation'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Android' className='navigation-icon' />
									<span className='navigation-text'>
										{t('Install_from_Google_Play') as ReactNode}
									</span>
								</span>
								
							</span>
						</div> */}
						
						{/* <div role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								showPWAInstallPrompt();
							}}
						>
							<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='PhoneIphone' className='navigation-icon' />
										<span className='navigation-text'>
											{t('Install_on_your_device') as ReactNode}
										</span>
									</span>
							</span>
						</div> */}

					</div>
				</nav>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;