import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
  isLight: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, isLight }) => {

  var darkOrWhiteColor = "#010502";
  if(isLight){
    darkOrWhiteColor = "#FFFFFF";
  }


	return (
	  <svg
		xmlns="http://www.w3.org/2000/svg"
		width={height !== 854 && !!height ? height * (2155 / 854) : width}
		height={width !== 2155 && !!width ? width * (854 / 2155) : height}
		viewBox="0 0 3200 922"
    fill={darkOrWhiteColor}
		style={{
			shapeRendering: "geometricPrecision",
			textRendering: "geometricPrecision",
			fillRule: "evenodd",
			clipRule: "evenodd",
		  }}
		>




      <path
        d="M887.5 103.5a4.934 4.934 0 013 .5c-.832 67.43-1.332 134.93-1.5 202.5l-11 11c-.667-67.667-.667-135.333 0-203a525.997 525.997 0 019.5-11z"
        opacity="0.897"
      ></path>
      <path
        d="M856.5 136.5c.543.06.876.393 1 1-.83 67.626-1.33 135.293-1.5 203a1475.306 1475.306 0 01-32.5 32 6936.404 6936.404 0 01-.5-204 1531.07 1531.07 0 0033.5-32z"
        opacity="0.959"
      ></path>
      <path
        d="M2346.5 181.5c22.76-3.21 38.92 5.456 48.5 26 6.2 22.41-.96 38.91-21.5 49.5-20 6.866-37.17 2.699-51.5-12.5-11.46-20.079-8.96-38.245 7.5-54.5 5.21-3.939 10.88-6.773 17-8.5z"
        opacity="0.979"
      ></path>
      <path
        d="M1929.5 198.5h83c-.17 63.334 0 126.668.5 190 3.05 22.061 15.55 31.561 37.5 28.5 17.8-5.769 25.3-17.935 22.5-36.5-5.59-16.585-17.09-23.751-34.5-21.5a44.778 44.778 0 00-14 6.5c-1-30.326-1.33-60.659-1-91 54.31-6.092 95.15 14.241 122.5 61 15.61 35.154 15.27 70.154-1 105-28.71 46.267-70.21 65.434-124.5 57.5-52.53-12.197-82.36-45.03-89.5-98.5a7087.952 7087.952 0 01-1.5-201z"
        opacity="0.986"
      ></path>
      <path d="M2565.5 198.5h82v297h-82v-297z" opacity="0.994"></path>
      <path
        d="M777.5 213.5c.597.235.93.735 1 1.5a21238.428 21238.428 0 00-1.5 204.5 3857.66 3857.66 0 01-52.5 52l-1-1c.663-68.295 1.33-136.629 2-205a1911.237 1911.237 0 0052-52z"
        opacity="0.986"
      ></path>
      <path
        d="M1166.5 274.5c31.04-3.267 57.21 6.399 78.5 29 14.52-14.17 31.69-23.67 51.5-28.5 41.17-5.005 71 10.829 89.5 47.5a135.023 135.023 0 016 22 3958.388 3958.388 0 011.5 151h-82c.17-42.335 0-84.668-.5-127-.88-10.362-6.38-14.196-16.5-11.5-4.08 2.312-6.58 5.812-7.5 10.5-.33 2.667-.67 5.333-1 8-.5 39.999-.67 79.999-.5 120h-82c.17-42.335 0-84.668-.5-127-1.23-9.871-6.73-13.704-16.5-11.5-4.08 2.312-6.58 5.812-7.5 10.5-.33 2.667-.67 5.333-1 8-.5 39.999-.67 79.999-.5 120h-82c-.17-47.335 0-94.668.5-142 5.57-42.553 29.07-68.887 70.5-79z"
        opacity="0.992"
      ></path>
      <path
        d="M1649.5 274.5c57.45-4.539 98.28 19.128 122.5 71 17.77 57.029 2.6 102.862-45.5 137.5-30.57 17.43-62.91 21.43-97 12-35.68-12.343-60.18-36.176-73.5-71.5-13.96-47.902-2.8-88.736 33.5-122.5 17.6-14.362 37.6-23.196 60-26.5zm8 82c19.2-2.276 31.7 5.724 37.5 24 1 25.999-11.5 38.166-37.5 36.5-14.67-4.667-22.67-14.667-24-30 1.53-15.353 9.53-25.52 24-30.5z"
        opacity="0.989"
      ></path>
      <path
        d="M2903.5 274.5c56.6-5.464 97.1 17.203 121.5 68 6.77 18.823 8.94 38.156 6.5 58-41.33.167-82.67 0-124-.5-4.14-12.438-3.8-24.772 1-37 13.66-.5 27.33-.667 41-.5-3.76-18.723-15.09-26.223-34-22.5-13.16 5.167-21.33 14.667-24.5 28.5-4.19 18.369-1.19 35.369 9 51 7.59 8.751 17.09 11.918 28.5 9.5 6.6-2.686 12.6-6.019 18-10a1767.573 1767.573 0 0145.5 54.5c.67.667.67 1.333 0 2-36.96 26.263-76.79 31.43-119.5 15.5-50.26-25.334-72.09-66.168-65.5-122.5 5.82-32.491 22.32-57.991 49.5-76.5 14.55-9.125 30.22-14.958 47-17.5z"
        opacity="0.987"
      ></path>
      <path d="M2314.5 278.5h82v217h-82v-217z" opacity="0.994"></path>
      <path
        d="M705.5 284.5c1.333 33.827 2 67.993 2 102.5l-.5 102.5a637.866 637.866 0 01-21.5 21 7004.561 7004.561 0 01-.5-205 605.464 605.464 0 0020.5-21z"
        opacity="0.937"
      ></path> 
      <path
        fill="#010502"
        d="M895.5 313.5a772.829 772.829 0 01.5 68c-1.269 10.534-5.269 19.867-12 28A35835.728 35835.728 0 00493.5 799a137.896 137.896 0 01-13 9c-5.667 2-11.333 2-17 0a86.705 86.705 0 01-20-15A8224.736 8224.736 0 00291 640.5a124.053 124.053 0 01-21-30 79.054 79.054 0 01-3-12 1055.972 1055.972 0 010-65c65.833 66.833 132 133.333 198.5 199.5 2.959 2.33 6.293 2.996 10 2a83419.159 83419.159 0 00420-421.5z"
        opacity="0.901"
      ></path>
      <path
        d="M268.5 318.5a2.428 2.428 0 012 .5 1513.057 1513.057 0 0033 32.5 5254.938 5254.938 0 010 205c-11-11.667-22.333-23-34-34-1-67.997-1.333-135.997-1-204z"
        opacity="0.962"
      ></path>
      <path
        d="M645.5 344.5c1.333 33.827 2 67.993 2 102.5l-.5 102.5-61 61c-.667-68.667-.667-137.333 0-206a5042.129 5042.129 0 0059.5-60z"
        opacity="0.969"
      ></path>
      <path
        d="M328.5 377.5a1358.068 1358.068 0 0154.5 53c.833 68.336.667 136.669-.5 205a4012.742 4012.742 0 01-53.5-53c-.5-68.333-.667-136.666-.5-205z"
        opacity="0.97"
      ></path>
      <path
        fill="#35b257"
        d="M890.5 380.5c-1.173 10.678-5.339 20.011-12.5 28a9289.969 9289.969 0 01-82.5 80 1026.64 1026.64 0 01-1-63 12702.172 12702.172 0 0194.5-95c.168 16.838.668 33.505 1.5 50z"
      ></path>
      <path
        fill="#3ac661"
        d="M890.5 380.5a1237.634 1237.634 0 01-1.5-50 12702.172 12702.172 0 00-94.5 95 5473.064 5473.064 0 00-62.5 62c-.399-.228-.565-.562-.5-1a36061.239 36061.239 0 01159.5-160c.829 18.171.662 36.171-.5 54z"
      ></path>
      <path
        d="M571.5 418.5c.876-.131 1.543.202 2 1a21207.53 21207.53 0 00-1.5 205l-11 11a7141.903 7141.903 0 01.5-207 70.852 70.852 0 0010-10z"
        opacity="0.9"
      ></path>
      <path
        fill="#2db05a"
        d="M794.5 425.5a1026.64 1026.64 0 001 63 159.435 159.435 0 01-15 15 1761.949 1761.949 0 00-62 60.5c.228.399.562.565 1 .5-8 8.667-16.333 17-25 25a43.836 43.836 0 01-2-1 580.852 580.852 0 01-.5-59 865.335 865.335 0 0024.5-25c-7.656 6.132-15.156 13.132-22.5 21-.399-.228-.565-.562-.5-1 12.333-13 25-25.667 38-38-.065.438.101.772.5 1a5473.064 5473.064 0 0162.5-62z"
      ></path>
      <path
        d="M411.5 459.5a54.53 54.53 0 016.5 6c.833 68.336.667 136.669-.5 205a38.929 38.929 0 01-5.5-5c-.5-68.666-.667-137.333-.5-206z"
        opacity="0.815"
      ></path>
      <path
        d="M521.5 468.5c.876-.131 1.543.202 2 1a21207.53 21207.53 0 00-1.5 205 1376.034 1376.034 0 01-42.5 43.5c-4.581 3.061-9.247 3.227-14 .5-.67-69.705-1.337-139.371-2-209 2.801-.61 5.635-.276 8.5 1a40.712 40.712 0 0014.5-6.5 351.735 351.735 0 0035-35.5z"
        opacity="0.987"
      ></path>
      <path
        d="M428.5 477.5a175.288 175.288 0 0111.5 11c.667 68.333.667 136.667 0 205l-11-11c-.5-68.333-.667-136.666-.5-205z"
        opacity="0.898"
      ></path>
      <path
        d="M1129.5 527.5c10.94-2.076 20.44.591 28.5 8 7.37 14.89 3.2 25.057-12.5 30.5-11.93 3.023-21.76-.143-29.5-9.5-4.8-10.263-2.64-18.763 6.5-25.5 2.43-1.213 4.76-2.379 7-3.5z"
        opacity="0.965"
      ></path>
      <path
        d="M2280.5 533.5h29v68h46v20h-46v163h-29v-163h-40v-20h40v-68z"
        opacity="0.997"
      ></path>
      <path
        fill="#32c464"
        d="M780.5 503.5c-20 20.667-40.333 41-61 61-.438.065-.772-.101-1-.5a1761.949 1761.949 0 0162-60.5z"
      ></path>
      <path
        fill="#03a266"
        d="M274.5 549.5a2.428 2.428 0 00.5 2 5912.777 5912.777 0 0164.5 65 1846.447 1846.447 0 00-1.5 61 221.165 221.165 0 00-22.5-21c-12.983-11.487-24.817-24.154-35.5-38-7.884-14.502-10.717-30.002-8.5-46.5l.5-25.5a10.515 10.515 0 012.5 3z"
      ></path>
      <path
        fill="#24ad5c"
        d="M694.5 589.5c-24 24.667-48.333 49-73 73-.385-21.432-1.385-42.432-3-63a3981.634 3981.634 0 0175-75c-.065.438.101.772.5 1 7.344-7.868 14.844-14.868 22.5-21a865.335 865.335 0 01-24.5 25 580.852 580.852 0 00.5 59c.683.363 1.35.696 2 1z"
      ></path>
      <path
        d="M1312.5 597.5c26.23-3.581 50.23 1.919 72 16.5 17.88 15.265 26.71 34.765 26.5 58.5.5 37.332.67 74.665.5 112h-29c.17-31.668 0-63.335-.5-95 1.54-53.783-24.29-76.283-77.5-67.5-40.07 10.751-59.57 36.918-58.5 78.5-.5 27.998-.67 55.998-.5 84h-30v-183h30c-.17 10.339 0 20.672.5 31 18.1-19.467 40.27-31.134 66.5-35z"
        opacity="0.97"
      ></path>
      <path
        d="M1801.5 597.5c33.83-3.979 65.17 2.854 94 20.5 26.64 18.449 40.31 43.949 41 76.5h-201c4.67 37.841 25.67 61.008 63 69.5 29.46 5.173 56.8-.16 82-16a130.815 130.815 0 0023-24c9.02 2.167 17.69 5.5 26 10-20.57 31.837-49.91 49.837-88 54-31.76 4.132-61.76-1.202-90-16-24.73-16.064-39.9-38.564-45.5-67.5-2.81-36.55 10.69-65.384 40.5-86.5 16.84-11.109 35.17-17.942 55-20.5zm4 23c24.13-2.701 46.8 1.799 68 13.5 14.93 9.321 24.93 22.488 30 39.5-54.66 1-109.33 1.333-164 1 6.71-21.547 20.38-37.047 41-46.5 8.22-3.3 16.56-5.8 25-7.5z"
        opacity="0.972"
      ></path>
      <path
        d="M2090.5 597.5c26.23-3.581 50.23 1.919 72 16.5 17.88 15.265 26.71 34.765 26.5 58.5.5 37.332.67 74.665.5 112h-29c.17-31.668 0-63.335-.5-95 1.54-53.783-24.29-76.283-77.5-67.5-40.07 10.751-59.57 36.918-58.5 78.5-.5 27.998-.67 55.998-.5 84h-30v-183h30c-.17 10.339 0 20.672.5 31 18.1-19.467 40.27-31.134 66.5-35z"
        opacity="0.97"
      ></path>
      <path
        d="M2490.5 597.5c37.91-4.527 71.91 4.64 102 27.5 30.92 27.097 40.09 60.264 27.5 99.5-17.31 35.282-45.47 56.115-84.5 62.5a236.834 236.834 0 01-53 0c-39.03-6.385-67.19-27.218-84.5-62.5-12.55-38.662-3.72-71.495 26.5-98.5 19.29-15.812 41.29-25.312 66-28.5zm4 24c34.44-4.024 63.28 6.642 86.5 32 22.72 34.749 18.55 65.916-12.5 93.5-29.44 19.716-61.1 24.049-95 13-33.45-12.759-50.29-36.926-50.5-72.5 2.52-18.522 11.02-33.689 25.5-45.5 13.62-10.813 28.96-17.646 46-20.5z"
        opacity="0.965"
      ></path>
      <path
        d="M2748.5 597.5c12.04-1.092 23.7.408 35 4.5.93.388 1.59 1.055 2 2a225.572 225.572 0 01-15 19.5c-28.5-7.001-48.67 2.999-60.5 30-4.62 13.601-6.95 27.601-7 42-.5 29.665-.67 59.331-.5 89h-30v-183h30c-.17 8.007 0 16.007.5 24 12.31-14.181 27.48-23.514 45.5-28z"
        opacity="0.978"
      ></path>
      <path d="M1122.5 601.5h30v183h-30v-183z" opacity="0.975"></path>
      <path
        d="M1456.5 602.5c10.49-1.159 21.16-1.325 32-.5 24.54 43.581 49.21 87.081 74 130.5 24.42-43.172 48.59-86.505 72.5-130 10.77-1.319 21.61-1.319 32.5 0a23759.121 23759.121 0 01-101 181c-3.11 1.483-5.94 1.15-8.5-1a23257.47 23257.47 0 00-101.5-180z"
        opacity="0.964"
      ></path>
      {/* <path
        d="M2791.5 601.5c10.58-.32 21.08.013 31.5 1 24.91 46.16 50.08 92.16 75.5 138a6786.667 6786.667 0 0073-138 182.064 182.064 0 0133-.5c-44.33 83-88.67 166-133 249-10.67.667-21.33.667-32 0a9398.056 9398.056 0 0143-80.5 12078.652 12078.652 0 01-91-169z"
        opacity="0.965"
      ></path> */}
      <path
        fill="#09a464"
        d="M339.5 616.5a5473.064 5473.064 0 0162.5 62 960.77 960.77 0 010 62L380.5 719c-.937-.692-1.603-.525-2 .5a2809.493 2809.493 0 01-63-63 221.165 221.165 0 0122.5 21c.168-20.504.668-40.837 1.5-61z"
      ></path>
      <path
        fill="#1caa5e"
        d="M618.5 599.5c1.615 20.568 2.615 41.568 3 63a396.092 396.092 0 01-34 33 2.428 2.428 0 00-2 .5 4233.264 4233.264 0 01-55 54.5 871.155 871.155 0 01-1-59 1422.753 1422.753 0 0131.5-32 2.428 2.428 0 00.5-2 1920.871 1920.871 0 0157-58z"
      ></path>
      <path
        fill="#0fa763"
        d="M274.5 549.5l192 191c.307 20.038-.026 40.371-1 61-9.197-5.689-17.364-12.689-24.5-21-.399.228-.565.562-.5 1a2716.936 2716.936 0 00-62-62c.397-1.025 1.063-1.192 2-.5l21.5 21.5a960.77 960.77 0 000-62 5473.064 5473.064 0 00-62.5-62 5912.777 5912.777 0 00-64.5-65 2.428 2.428 0 01-.5-2z"
      ></path>
      <path
        fill="#1abd6b"
        d="M561.5 657.5a2.428 2.428 0 01-.5 2 1422.753 1422.753 0 00-31.5 32 1653.338 1653.338 0 00-34.5 34c-.399-.228-.565-.562-.5-1 22-22.667 44.333-45 67-67z"
      ></path>
      <path
        fill="#15a861"
        d="M529.5 691.5c-.33 19.84.003 39.507 1 59a25.497 25.497 0 00-5 7 705.28 705.28 0 01-38 39.5 54.103 54.103 0 01-12 7c-5.111 1.111-9.778.111-14-3a116.458 116.458 0 01-21-19.5c-.065-.438.101-.772.5-1 7.136 8.311 15.303 15.311 24.5 21 .974-20.629 1.307-40.962 1-61 3.711 1.384 7.377 1.218 11-.5 6.487-4.323 12.154-9.49 17-15.5-.065.438.101.772.5 1a1653.338 1653.338 0 0134.5-34z"
      ></path>
      <path
        fill="#1ec06b"
        d="M587.5 695.5c-20.333 21-41 41.667-62 62a25.497 25.497 0 015-7 4233.264 4233.264 0 0055-54.5 2.428 2.428 0 012-.5z"
      ></path>



<g  transform="translate(2600, 322) ">
    <g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
         stroke="none">
      <path d="M3007 2992 c-1682 -1682 -2957 -2965 -2957 -2975 0 -16 76 -17 1458
      -17 l1457 0 1518 1518 1517 1517 0 1458 c0 1381 -1 1457 -18 1457 -9 0 -1290
      -1273 -2975 -2958z m2123 678 c300 -133 547 -244 550 -246 2 -3 -35 -44 -82
      -91 -101 -101 -81 -99 -252 -19 l-98 46 -161 -159 -161 -159 56 -124 56 -123
      -89 -90 c-48 -49 -90 -87 -92 -85 -2 3 -111 259 -242 569 l-238 564 86 85 c61
      59 92 83 104 79 10 -3 263 -114 563 -247z m-1075 -520 l-120 -120 120 -123
      c66 -67 214 -217 328 -333 114 -116 207 -215 207 -220 0 -5 -36 -45 -80 -89
      l-81 -80 -97 100 c-54 55 -204 209 -333 342 l-236 241 -104 -104 c-57 -58
      -112 -110 -123 -116 -16 -8 -28 0 -82 53 -36 35 -63 69 -62 75 2 6 147 151
      323 322 l320 312 70 -70 70 -70 -120 -120z m-792 -638 l68 -69 -220 -213 -220
      -213 80 -88 c45 -49 84 -89 88 -89 4 0 98 88 210 197 l203 196 70 -70 70 -70
      -205 -198 -205 -199 100 -106 c55 -58 105 -107 110 -108 5 -2 111 96 235 219
      l227 223 67 -73 68 -73 -222 -217 c-122 -119 -263 -256 -313 -304 l-91 -88
      -397 406 c-218 223 -396 410 -396 415 0 9 591 590 600 590 3 0 36 -31 73 -68z
      m-827 -930 c58 -30 103 -78 132 -141 21 -45 24 -64 20 -125 l-5 -72 51 18 c28
      9 65 17 82 18 46 0 121 -25 164 -55 84 -58 148 -199 136 -299 -12 -105 -40
      -143 -305 -405 l-246 -243 -293 298 c-161 165 -340 350 -399 412 l-107 113
      140 141 c244 246 346 330 434 359 43 14 152 4 196 -19z"/>
      <path d="M4694 3558 c18 -40 57 -127 86 -193 30 -66 55 -122 57 -124 2 -3 51
      42 108 99 58 57 101 108 97 112 -8 8 -366 178 -376 178 -3 0 9 -33 28 -72z"/>
      <path d="M2193 1341 c-26 -21 -87 -79 -135 -127 l-86 -89 87 -93 88 -92 120
      117 c95 93 124 127 133 160 16 53 7 88 -35 130 -28 28 -40 33 -78 33 -38 0
      -53 -7 -94 -39z"/>
      <path d="M2549 1042 c-24 -16 -93 -76 -154 -134 l-110 -106 100 -105 c55 -57
      105 -105 111 -106 16 -2 262 253 274 283 25 66 -1 138 -62 176 -48 29 -105 26
      -159 -8z"/>
    </g>
  </g>



    </svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
