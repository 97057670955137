import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import LangSelector from '../../_common/LangSelector';

import { Trans, useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { auth } from '../../../helpers/firebase';
import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	GoogleAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
	User as FirebaseUser,
} from 'firebase/auth';

import showNotification from '../../../components/extras/showNotification';
import { User } from '../../../common/data/models/user';

import { endpoints } from '../../../helpers/api';
import { sendRequest } from '../../../helpers/api';

const provider = new GoogleAuthProvider();

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>
					<Trans>Create_account</Trans>,
				</div>
				<div className='text-center h4 text-muted mb-5'>
					<Trans>Sign_up_to_get_started</Trans>
				</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>
				<Trans>Welcome</Trans>,
			</div>
			<div className='text-center h4 text-muted mb-5'>
				<Trans>Sign_in_to_continue</Trans>
			</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser, updateUser} = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const { boot, update, shutdown } = useIntercom();

	const { t } = useTranslation();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const [passwordResetClicked, setPasswordResetClicked] = useState<boolean>(false);


	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const handleSignIn = async (email: string, password: string) => {
		setIsLoading(true);

		try {
			const user = await signInWithEmailAndPassword(auth, email, password);

			await onAuthenticatedCallback(user.user);

			setIsLoading(false);

			handleOnClick();
		} catch (error) {
			setIsLoading(false);
			showNotification('Error: ', error + '', 'danger');
		}
	};

	const handleSignUp = async (email: string, name: string, surname: string, password: string) => {
		setIsLoading(true);

		try {
			const userCredential = await createUserWithEmailAndPassword(auth, email, password);

			await updateProfile(userCredential.user, {
				displayName: name + ' ' + surname,
			});

			await onAuthenticatedCallback(userCredential.user)

			setIsLoading(false);

			handleOnClick();
				
		} catch (error) {
			setIsLoading(false);
			showNotification('Error: ', error + '', 'danger');
		}
	};

	const handleGoogleSignIn = async () => {
		try {
			const result = await signInWithPopup(auth, provider);

			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential?.accessToken;
			// The signed-in user info.
			const user = result.user;

			await onAuthenticatedCallback(user);

			handleOnClick();
		} catch (error) {
			console.error('Sign-in error:', error);
		} 
	}; 

	const onAuthenticatedCallback = async(user: FirebaseUser) => {
		const token = await user.getIdToken();

		const localUser = new User(user.uid, user.displayName, user.email, user.photoURL, token);

		shutdown();
		boot({
			name: localUser.displayName || '',
			email: localUser.email || '',
			userId: localUser.uid,
		}); 
 
  
		const response = await sendRequest(endpoints.addUserSession(user.uid));
		let responseData = response as any;

		console.log("add session response", responseData);

		if (responseData.status != "0") {
			localUser.sessionId = responseData.sessionId;
			if(setUser){
				await setUser(localUser);
				localStorage.removeItem('selectedidInventory');
			}
		}  
	}


	const handleResetPassword = async (email:string) => {
		setIsLoading(true);
		setPasswordResetClicked(true);

		try {
			sendPasswordResetEmail(auth, email).then(() => { 
				console.log('Password Reset Email Sent!');
				setPasswordResetClicked(true);
			  }).catch((error) => {
				showNotification('Error: ', error + '', 'danger');
				setPasswordResetClicked(false);

				console.error('Error sending password reset email:', error);
			  });

			setIsLoading(false);

		} catch (error) {
			setIsLoading(false);
			showNotification('Error: ', error + '', 'danger');
		} 
	};



	// useEffect(() => {
	// 	const unsubscribe = auth.onAuthStateChanged((currentUser) => {
	// 		if (currentUser) {
	// 			// User is signed in, see docs for a list of available properties
	// 			// https://firebase.google.com/docs/reference/js/firebase.User
	// 			const email = currentUser.email;

	// 			if (setUser) {
	// 				setUser(currentUser);
	// 			}

	// 			showNotification('Welcome', 'D: ' + email);
	// 			// ...
	// 		} else {
	// 			showNotification('Signed out', '');
	// 			// User is signed out
	// 			// ...
	// 		}
	// 	});

	// 	// Clean up the listener on unmount
	// 	return () => unsubscribe();
	// }, []);


	const signUpTxt = t('Sign_Up');
	const loginTxt = t('Login');
	const yourEmailOrUsernameTxt = t('Your_email_or_username');
	const looksGoodTxt = t('Looks_good');
	const requiredTxt = t('Required');

	const yourEmailTxt = t('Your_email');
	const yourNameTxt = t('Your_name');
	const yourSurnameTxt = t('Your_surname');
	const passwordTxt = t('Password');

	const formikSignUp = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			name: '',
			surname: '',
			password: '',
		},
		validate: (values) => {
			const errors: { email?: string; name?: string; surname?: string; password?: string } =
				{};

			if (!values.email) {
				errors.email = requiredTxt;
			}

			if (!values.name) {
				errors.name = requiredTxt;
			}

			if (!values.surname) {
				errors.surname = requiredTxt;
			}

			if (!values.password) {
				errors.password = requiredTxt;
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSignUp(values.email, values.name, values.surname, values.password);
		},
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '', 
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = requiredTxt;
			}

			if (!values.loginPassword) {
				errors.loginPassword = requiredTxt;
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSignIn(values.loginUsername, values.loginPassword);

			// if (usernameCheck(values.loginUsername)) {
			// 	if (passwordCheck(values.loginUsername, values.loginPassword)) {
			// 		if (setUser) {
			// 			setUser(values.loginUsername);
			// 		}

			// 		handleOnClick();
			// 	} else {
			// 		formik.setFieldError('loginPassword', 'Username and password do not match.');
			// 	}
			// }
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = () => {
		setSignInPassword(true);

		// setIsLoading(true);
		// setTimeout(() => {
		// 	if (
		// 		!Object.keys(USERS).find(
		// 			(f) => USERS[f].username.toString() === formik.values.loginUsername,
		// 		)
		// 	) {
		// 		formik.setFieldError('loginUsername', 'No such user found in the system.');
		// 	} else {
		// 		setSignInPassword(true);
		// 	}
		// 	setIsLoading(false);
		// }, 1000);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? signUpTxt : loginTxt}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} isLight={false}/>
									</Link>
								</div>

								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-3 g-3 pb-3 px-3 mt-0'>
										<div className='col-5'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												<Trans>Login</Trans>
											</Button>
										</div>
										<div className='col-5'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												<Trans>Sign_Up</Trans>
											</Button>
										</div>
										<div className='col-2'>
											<LangSelector />
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='email'
													isFloating
													label={yourEmailTxt}>
													<Input
														type='email'
														autoComplete='email'
														value={formikSignUp.values.email}
														isTouched={formikSignUp.touched.email}
														invalidFeedback={formikSignUp.errors.email}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='name' isFloating label={yourNameTxt}>
													<Input
														autoComplete='given-name'
														value={formikSignUp.values.name}
														isTouched={formikSignUp.touched.name}
														invalidFeedback={formikSignUp.errors.name}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='surname'
													isFloating
													label={yourSurnameTxt}>
													<Input
														autoComplete='family-name'
														value={formikSignUp.values.surname}
														isTouched={formikSignUp.touched.surname}
														invalidFeedback={
															formikSignUp.errors.surname
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label={passwordTxt}>
													<Input
														type='password'
														autoComplete='password'
														value={formikSignUp.values.password}
														isTouched={formikSignUp.touched.password}
														invalidFeedback={
															formikSignUp.errors.password
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={formikSignUp.handleSubmit}>
													{isLoading && (
														<Spinner isSmall inButton />
													)}
													<Trans>Sign_Up</Trans>
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label={yourEmailOrUsernameTxt}
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														<Trans>Hi</Trans>,{' '}
														{formik.values.loginUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback={looksGoodTxt}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
														<Button
															color='warning'
															className='w-100 py-3'
															isDisable={!formik.values.loginUsername}
															onClick={handleContinue}>
															{isLoading && (
																<Spinner isSmall inButton />
															)}
															<Trans>Continue</Trans>
														</Button>
												) : (
													<>
														<Button
															color='warning'
															className='w-100 py-3'
															isDisable={isLoading}
															onClick={formik.handleSubmit}>
															{isLoading && (
																<Spinner isSmall inButton />
															)}
															<Trans>Login</Trans>
														</Button>
														<br/><br/>

														{passwordResetClicked && (
															<div className='text-muted'>
																&nbsp;&nbsp;<Trans>trans_forgot_password_clicked</Trans>&nbsp;
															</div>
														)} 

														{!passwordResetClicked && (
															<div className='text-muted'>
																&nbsp;&nbsp;<Trans>trans_forgot_password</Trans>&nbsp;
																<a 
																	style={{ cursor: 'pointer' }} 
																	onClick={() => {
																		handleResetPassword(formik.values.loginUsername);
																	}}
																><Trans>click_here_to_reset_it</Trans></a>
															</div>
														)}
														
													</>
												)}
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												<Trans>OR</Trans>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleGoogleSignIn}>
													<Trans>Continue_with_google</Trans>
												</Button>
											</div>
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								<Trans>Privacy_policy</Trans>
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								<Trans>Terms_of_use</Trans>
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
