import React, { SVGProps } from 'react';

const SvgCustomSpain = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" 
		viewBox='0 0 512 512'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}
		style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", fillRule: "evenodd", clipRule: "evenodd"}} xmlnsXlink="http://www.w3.org/1999/xlink">
		<g><path style={{opacity: 1}} fill="#c52227" d="M 671.5,109.5 C 448.833,109.5 226.167,109.5 3.5,109.5C 3.5,84.8333 3.5,60.1667 3.5,35.5C 3.59909,30.198 5.26575,25.5313 8.5,21.5C 9.90632,21.0269 10.573,20.0269 10.5,18.5C 16.3937,10.378 24.3937,5.54465 34.5,4C 236.5,3.33333 438.5,3.33333 640.5,4C 656.453,7.28572 666.62,16.7857 671,32.5C 671.5,58.1645 671.667,83.8312 671.5,109.5 Z"/></g>
		<g><path style={{opacity: 1}} fill="#e89897" d="M 10.5,18.5 C 10.573,20.0269 9.90632,21.0269 8.5,21.5C 8.42702,19.9731 9.09368,18.9731 10.5,18.5 Z"/></g>
		<g><path style={{opacity: 1}} fill="#f7b364" d="M 3.5,35.5 C 3.5,60.1667 3.5,84.8333 3.5,109.5C 3.5,193.833 3.5,278.167 3.5,362.5C 226.167,362.5 448.833,362.5 671.5,362.5C 671.5,278.167 671.5,193.833 671.5,109.5C 672.833,194.167 672.833,278.833 671.5,363.5C 448.833,363.5 226.167,363.5 3.5,363.5C 3.5,387.833 3.5,412.167 3.5,436.5C 2.16667,302.833 2.16667,169.167 3.5,35.5 Z"/></g>
		<g><path style={{opacity: 1}} fill="#fec50a" d="M 3.5,109.5 C 226.167,109.5 448.833,109.5 671.5,109.5C 671.5,193.833 671.5,278.167 671.5,362.5C 448.833,362.5 226.167,362.5 3.5,362.5C 3.5,278.167 3.5,193.833 3.5,109.5 Z"/></g>
		<g><path style={{opacity: 1}} fill="#c52327" d="M 671.5,363.5 C 671.965,390.551 671.465,417.551 670,444.5C 664.495,458.499 654.328,466.665 639.5,469C 437.833,469.667 236.167,469.667 34.5,469C 16.7832,465.118 6.44988,454.285 3.5,436.5C 3.5,412.167 3.5,387.833 3.5,363.5C 226.167,363.5 448.833,363.5 671.5,363.5 Z"/></g>
  </svg>
);

export default SvgCustomSpain;