import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, publicPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DefaultHeader from '../pages/_layout/_headers/LoggedInDefaultHeader';
import LandingPageHeader from '../pages/_layout/_headers/LandingPageHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: publicPagesMenu.login.path, element: null },
	{ path: publicPagesMenu.signUp.path, element: null },
	{ path: publicPagesMenu.page404.path, element: null },
	{ path: publicPagesMenu.landingPage.path, element: <LandingPageHeader />  },
	{
		path: `*`,
		element: <DefaultHeader />, 
	},
];

export default headers;
