import React, {
	createContext,
	FC,
	ReactNode,
	useEffect,
	useState,
	useMemo,
	useCallback,
	useContext
  } from 'react';
  import PropTypes from 'prop-types';
  import AuthContext from '../contexts/authContext';

  import { useNavigate } from 'react-router-dom';
  import { publicPagesMenu } from '../menu';

  
import { endpoints,sendRequest } from '../helpers/api';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../components/bootstrap/Modal';
import ConfirmModal from '../pages/_common/ConfirmModal';
import showNotification from '../components/extras/showNotification';
import { auth } from '../helpers/firebase';
import { Trans, useTranslation } from 'react-i18next';

import { useIntercom } from 'react-use-intercom';

  // Types
  export interface IUserInfoContextProps {
	userInfo: any;
	setUserInfo?(userInfo: any): void;
	isLoading: boolean;
	fetchUserInfo?(): Promise<void>;
  }
  
  interface IUserInfoContextProviderProps {
	children: ReactNode;
  }
  
  const UserInfoContext = createContext<IUserInfoContextProps>(
	{} as IUserInfoContextProps
  ); 
  
  export const UserInfoContextProvider: FC<IUserInfoContextProviderProps> = ({ children }) => {
	const [userInfo, setUserInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const { user, setUser } = useContext(AuthContext);

	const [anotherActiveSession, setAnotherActiveSession] = useState<any>(null);
	const { boot, update, shutdown } = useIntercom();
	  

	const fetchUserInfo = useCallback(async () => {
		if (!user) return;
		try {
		  console.log("fetchingUserInfo");
		
		  const url = endpoints.getUserInfo(); 
		  const response: any  = await sendRequest(endpoints.getUserInfo());
		  const userInfo = response.data.user;


		  update({userHash: userInfo.configs.intercomHashWeb});
		  

		  console.log("user", user);
		  console.log("userInfo", userInfo);
		  console.log("userInfo?.sessions", userInfo?.sessions);

		  

		//userInfo?.sessions:  {
		// 	"aBKbBKQZTY8611M7": {
		// 		"device": {
		// 			"clientType": "browser",
		// 			"osVersion": "10.15",
		// 			"client": "Chrome",
		// 			"type": "desktop",
		// 			"osName": "Mac",
		// 			"brand": "Apple"
		// 		},
		// 		"loggedInAt": {
		// 			"_seconds": 1709565539,
		// 			"_nanoseconds": 959000000
		// 		},
		//		"isActive": true,
		// 	},	
		// }


	
		  setUserInfo(userInfo);

		
		// 1. logout if the user does not exist in the session list
		// Show popup if another active session exists besides the current one
		let userSessionId: string = user?.sessionId || "";
		let anotherActiveSession: any | null = null;
		if (userInfo.sessions) {
			for (let i = 0; i < userInfo?.sessions.length; i++) {
				let oneSession: any|null = userInfo?.sessions[i];
				let loggedOutMessageTitle = t("trans_another_session_modal_title");
				let loggedOutMessage = t("trans_logged_out_from_another_device");
				if (!oneSession?.isActive && oneSession?.sessionId == userSessionId) {
					if (setUser) {
						setUser(null);
					}
					showNotification(loggedOutMessageTitle,loggedOutMessage, 'warning');
					navigate(`../${publicPagesMenu.login.path}`);
					return;
				}

				if (oneSession?.isActive && oneSession?.sessionId !== userSessionId) {
					anotherActiveSession = oneSession;
				}
			}
		}

		console.log("userSessionId",userSessionId);

		console.log("anotherActiveSession", anotherActiveSession);
		setAnotherActiveSession(anotherActiveSession);



		  setIsLoading(false);
		} catch (error) {
		  console.error("Failed to fetch user info: ", error);
		  setIsLoading(false);
		}
	  }, [user]); 
	
	  useEffect(() => {
		if (user) { 
		  fetchUserInfo();
		} else {
			setIsLoading(false);
		}
	  }, [fetchUserInfo, user]);


	  const value = useMemo(
		() => ({
		  userInfo,
		  setUserInfo,
		  isLoading,
		  fetchUserInfo,
		}),
		[userInfo, isLoading, fetchUserInfo]
	  );

	  let userInfoDevice = "N/A";
	  if(anotherActiveSession?.device != null) {
		userInfoDevice = anotherActiveSession?.device?.brand + " " + anotherActiveSession?.device?.osName;
	  }
	
	  return (
		<UserInfoContext.Provider value={value}>{children}
		
		<ConfirmModal titleText={t("trans_another_session_modal_title") || ""}
				descriptionText={t("trans_another_session_modal_description", { p1: userInfoDevice }) || ""} 
				greenButtonText={t("trans_another_session_modal_continue") || ""}
				redButtonText={t("trans_another_session_modal_logout") || ""}
				isOpen={anotherActiveSession != null}
				onClose={() => {
					setAnotherActiveSession(anotherActiveSession);
				}}
				greenButtonClicked={async () => {
					try {
						if(!user?.sessionId){
							showNotification("Error", "user?.sessionId is null", 'warning');
							return;
						}
						let sessionId: string = user?.sessionId;
						await sendRequest(endpoints.removeAllUserSessionsExceptCurrent(sessionId));
						setAnotherActiveSession(anotherActiveSession);
						fetchUserInfo();
					} catch (error) {
						console.error("Failed to remove other sessions: ", error);
					}
				}} 
				redButtonClicked={async () => {

					try {
						
						if(user?.sessionId){
							await sendRequest(endpoints.logout(user?.sessionId));
						}
						
						await auth.signOut();
						localStorage.removeItem('selectedidInventory');
						if (setUser) {
							setUser(null);
						}

						setAnotherActiveSession(null);
						navigate(`../${publicPagesMenu.login.path}`);
					} catch (error) {
						console.error("Failed to logout: ", error);
					}
					
				}}
			/>

		</UserInfoContext.Provider>
	  );
	};
  
  
  export default UserInfoContext;
  