import UserImage1 from '../../assets/img/avatars/1.png';
import UserImage2 from '../../assets/img/avatars/2.png';
import UserImage3 from '../../assets/img/avatars/3.png';
import UserImage4 from '../../assets/img/avatars/4.png';
import UserImage5 from '../../assets/img/avatars/5.png';
import UserImage6 from '../../assets/img/avatars/6.png';
import UserImage7 from '../../assets/img/avatars/7.png';



export interface IAvatarImageProps {
	src: string;
	srcSet: string;
}

const maleAvatars: IAvatarImageProps[] = [
	{
		src: UserImage1,
		srcSet: UserImage1,
	},
	{
		src: UserImage3,
		srcSet: UserImage3,
	},
	{
		src: '../../assets/img/avatars/5.png',
		srcSet: '../../assets/img/avatars/5.png',
	},
	{
		src: '../../assets/img/avatars/6.png',
		srcSet: '../../assets/img/avatars/6.png',
	},
	{
		src: '../../assets/img/avatars/7.png',
		srcSet: '../../assets/img/avatars/7.png',
	},
	{
		src: '../../assets/img/avatars/8.png',
		srcSet: '../../assets/img/avatars/8.png',
	},
];

const femaleAvatars: IAvatarImageProps[] = [ 
	{
		src: UserImage2,
		srcSet: UserImage2,
	},
	{
		src: '../../assets/img/avatars/4.png',
		srcSet: '../../assets/img/avatars/4.png',
	},
	{
		src: '../../assets/img/avatars/9.png',
		srcSet: '../../assets/img/avatars/9.png',
	},
	{
		src: '../../assets/img/avatars/10.png',
		srcSet: '../../assets/img/avatars/10.png',
	},
	
];

export { maleAvatars, femaleAvatars };


