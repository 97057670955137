import { Tag } from "../common/data/models/tag";
import i18n from "../i18n";

import { refreshFirebaseToken } from './firebase';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';



export const endpoints = {
	getPublicInventories:(): IEndpoint => ({
		url: `${API_BASE_URL}/public/v1/inventories/getPublicInventories`,
		method: 'GET'
	}),

	getInventories: (): IEndpoint => ({
		url: `${API_BASE_URL}/private/v1/inventories/getInventories`,
		method: 'GET',
	}),

	getInventoriesFromMySqlAndFirestore: (): IEndpoint => ({
		url: `${API_BASE_URL}/private/v1/inventories/getInventoriesFromMySqlAndFirestore`,
		method: 'GET',
	}),
	syncInventory: (idInventory: string | null, idInventoryFb: string | null): IEndpoint => ({
		url: `${API_BASE_URL}/private/v1/inventories/syncInventory`,
		method: 'GET',
		header: buildHeader(idInventory, idInventoryFb)
	}),
	


	insertInventory: (name: string, type: number, added: string): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/inventories/insertInventory`,
		method: 'POST',
        body: { name, type, added }
    }),
	updateInventory: (idInventory: string, idInventoryFb:string | null, name: string, type: number): IEndpoint => ({
        url: `${API_BASE_URL}/private-inv/v1/inventories/updateInventory`,
		method: 'PUT',
        body: { name, type },
		header: buildHeader(idInventory, idInventoryFb)
    }), 

	deleteInventory: (idInventory: string, idInventoryFb:string | null): IEndpoint => ({
        url: `${API_BASE_URL}/private-inv/v1/inventories/deleteInventory`,
		method: 'DELETE',
		header: buildHeader(idInventory, idInventoryFb)
    }),
	
	// Removes the user from this inventory in firebase
	removeInventoryFromTheCloud: (idInventoryFb:string | null): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/inventories/removeInventoryFromTheCloud`,
		method: 'PATCH',
		header: buildHeader(null, idInventoryFb)
    }),

	shareInventory: (idInventory: string, idInventoryFb:string | null, email: string): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/inventories/shareInventory`,
		method: 'PATCH',
		header: buildHeader(idInventory, idInventoryFb),
		body: { email }
    }),
   
	/* 
		Products 
	*/
		getPublicProducts: (idInventory: string, forTableDisplay: boolean): IEndpoint => ({
			url: `${API_BASE_URL}/public/v1/products/getPublicProducts?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: { idinventory: idInventory}
		}),
		
		getProducts: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
			return {
				url: `${API_BASE_URL}/private-inv/v1/products/getProducts?forTableDisplay=${forTableDisplay}`,
				method: 'GET',
				header: buildHeader(idInventory, idInventoryFb)
			};
		},
		
		insertProduct: (idInventory: string, idInventoryFb: string | null, productName: string, sku: string, barcode: string
			, selectedidCategory: string | undefined, selectedidCategoryFb: string | undefined, selectedidMu: string | undefined, selectedidMuFb: string | undefined
			, added: string, fieldValues: any): IEndpoint => ({
			url: `${API_BASE_URL}/private-inv/v1/products/insertProduct`,
			method: 'POST',
			body: { productName, sku, barcode, selectedidCategory, selectedidCategoryFb, selectedidMu, selectedidMuFb, added, fieldValues },
			header: buildHeader(idInventory, idInventoryFb)
		}),
		
		updateProduct: (idNomProduct: string, idNomProductFb: string, idInventory: string, idInventoryFb: string | null,  productName: string, sku: string, barcode: string
			, selectedidCategory: string | undefined, selectedidCategoryFb: string | undefined, selectedidMu: string | undefined, selectedidMuFb: string | undefined
			, modified: string, fieldValues: any): IEndpoint => ({
			url: `${API_BASE_URL}/private-inv/v1/products/updateProduct`,
			method: 'PUT',
			body: { idNomProduct, idNomProductFb, productName, sku, barcode, selectedidCategory, selectedidCategoryFb, selectedidMu, selectedidMuFb, modified, fieldValues },
			header: buildHeader(idInventory, idInventoryFb)
		}),
		
		deleteProduct: (idInventory: string, idInventoryFb: string | null, idProduct: string, idProductFb: string):IEndpoint => ({
			url: `${API_BASE_URL}/private-inv/v1/products/deleteProduct`,
			method: 'DELETE',
			body: { idProduct,idProductFb },
			header: buildHeader(idInventory, idInventoryFb)
		}),

		importProducts: (idInventory: string, idInventoryFb: string | null, selectedFieldsIds: string[], fileCode: string): IEndpoint => ({
			url: `${API_BASE_URL}/private-inv/v1/products/importProducts`,
			method: 'POST',
			body: { selectedFieldsIds, fileCode },
			header: buildHeader(idInventory, idInventoryFb)
		}),
	
		
	/* 
		Transactions 
	*/
	getTransactions: (idInventory: string, idInventoryFb: string, idProduct?: string): IEndpoint => {
		const idProductQuery = idProduct ? `?idProduct=${idProduct}` : '';
		return {
			url: `${API_BASE_URL}/private-inv/v1/transactions/getTransactions${idProductQuery}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},
	
	getPublicTransactions: (idInventory: string, idProduct?: string): IEndpoint => {
		const idProductQuery = idProduct ? `?idProduct=${idProduct}` : '';
	
		return {
			url: `${API_BASE_URL}/public/v1/transactions/getPublicTransactions${idProductQuery}`,
			method: 'GET',
			header: { idinventory: idInventory}
		};
	},
	
	insertTransaction: (idInventory: string, idInventoryFb: string | null,  idProduct: string, idProductFb: string | undefined, idLocation: string | undefined
		, idLocationFb: string | undefined, quantity: string, transactionType: string, moveToLocation: string | undefined, moveToLocationFb: string | undefined
		, added: string, fieldValues: any, selectedTags: Tag[]): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transactions/insertTransaction`,
		method: 'POST',
		body: { idProduct, idProductFb, idLocation, idLocationFb, quantity, transactionType, moveToLocation, moveToLocationFb, added, fieldValues, selectedTags },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	 
	updateTransaction: (idInventory: string, idInventoryFb: string | null, idDOperationHistory: string, idDOperationHistoryFb: string|undefined, idLocation: string | undefined
							, idLocationFb: string | undefined, idProductFb: string | undefined,  quantity: string, transactionType: string, modified: string, fieldValues: any
							, selectedTags: Tag[]): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transactions/updateTransaction`,
		method: 'PUT',
		body: { idDOperationHistory, idDOperationHistoryFb, idLocation, idLocationFb, idProductFb, quantity, transactionType
					, modified, fieldValues, selectedTags },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	
	deleteTransaction: (idInventory: string, idInventoryFb: string | null, idDOperationHistory: string, idDOperationHistoryFb: string|undefined, modified: string):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transactions/deleteTransaction`,
		method: 'DELETE',
		body: { idDOperationHistory, idDOperationHistoryFb, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),


	/* Nomeclatures */


	/* Locations */

	getLocations: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/locations/getLocations?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},
	insertLocation: (idInventory: string, idInventoryFb: string | null, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/locations/insertLocation`,
		method: 'POST',
		body: { name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	updateLocation: (idNomLocation: string, idNomLocationFb: string | undefined, idInventory: string, idInventoryFb: string | null
						, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/locations/updateLocation`,
		method: 'PUT',
		body: { idNomLocation, idNomLocationFb, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	setDefaultLocation: (idInventory: string, idInventoryFb: string | null, idNomLocation: string, idNomLocationFb: string | undefined): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/locations/setDefaultLocation`,
		method: 'PATCH',
		body: { idNomLocation, idNomLocationFb},
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteLocation: (idInventory: string, idInventoryFb: string | null, idNomLocation: string, idNomLocationFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/locations/deleteLocation`,
		method: 'DELETE',
		body: { idNomLocation, idNomLocationFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),


	/* Categories */
	
	getCategories: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/categories/getCategories?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},
	insertCategory: (idInventory: string, idInventoryFb: string | null, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/categories/insertCategory`,
		method: 'POST',
		body: { name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	updateCategory: (idNomCategory: string, idNomCategoryFb: string | undefined, idInventory: string, idInventoryFb: string | null
						, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/categories/updateCategory`,
		method: 'PUT',
		body: { idNomCategory, idNomCategoryFb, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteCategory: (idInventory: string, idInventoryFb: string | null, idNomCategory: string, idNomCategoryFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/categories/deleteCategory`,
		method: 'DELETE',
		body: { idNomCategory, idNomCategoryFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),




	/* Mus */
	
	getMus: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint=> {
		return {
			url: `${API_BASE_URL}/private-inv/v1/mus/getMus?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},
	insertMu: (idInventory: string, idInventoryFb: string | null, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/mus/insertMu`,
		method: 'POST',
		body: { name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	updateMu: (idNomMu: string, idNomMuFb: string | undefined, idInventory: string, idInventoryFb: string | null
						, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/mus/updateMu`,
		method: 'PUT',
		body: { idNomMu, idNomMuFb, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteMu: (idInventory: string, idInventoryFb: string | null, idNomMu: string, idNomMuFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/mus/deleteMu`,
		method: 'DELETE',
		body: { idNomMu, idNomMuFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),
		


	/* Product custom fields */

	getProductFields: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/product-fields/getProductFields?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},

	insertProductField: (idInventory: string, idInventoryFb: string | null, idType: number, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/product-fields/insertProductField`,
		method: 'POST',
		body: { idType, name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	updateProductField: (idNomField: string, idNomFieldFb: string | undefined, idInventory: string, idInventoryFb: string | null
						,idType: number, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/product-fields/updateProductField`,
		method: 'PUT',
		body: { idNomField, idNomFieldFb, idType, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteProductField: (idInventory: string, idInventoryFb: string | null, idNomField: string, idNomFieldFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/product-fields/deleteProductField`,
		method: 'DELETE',
		body: { idNomField, idNomFieldFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),


	getLProductFields: (idInventory: string, idInventoryFb: string, idProduct: string, idProductFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/product-fields/getLProductFields?forTableDisplay=${forTableDisplay}&idProduct=${idProduct}&idProductFb=${idProductFb}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb),
		};
	},







	


	/* Transaction custom fields */

	getTransactionFields: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/transaction-fields/getTransactionFields?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},

	insertTransactionField: (idInventory: string, idInventoryFb: string | null, idType: number, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transaction-fields/insertTransactionField`,
		method: 'POST',
		body: { idType, name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	updateTransactionField: (idNomOperationField: string, idNomOperationFieldFb: string | undefined, idInventory: string, idInventoryFb: string | null
						,idType: number, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transaction-fields/updateTransactionField`,
		method: 'PUT',
		body: { idNomOperationField, idNomOperationFieldFb, idType, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteTransactionField: (idInventory: string, idInventoryFb: string | null, idNomOperationField: string, idNomOperationFieldFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/transaction-fields/deleteTransactionField`,
		method: 'DELETE',
		body: { idNomOperationField, idNomOperationFieldFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),



	getLTransactionFields: (idInventory: string, idInventoryFb: string, idOperation: string, idOperationFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/transaction-fields/getLOperationFields?forTableDisplay=${forTableDisplay}&idOperation=${idOperation}&idOperationFb=${idOperationFb}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb),
		};
	},


	getLTransactionTags: (idInventory: string, idInventoryFb: string, idOperation: string, idOperationFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/transaction-tags/getLOperationTags?forTableDisplay=${forTableDisplay}&idOperation=${idOperation}&idOperationFb=${idOperationFb}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb),
		};
	},


	/* Tags */
	
	getTags: (idInventory: string, idInventoryFb: string, forTableDisplay: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private-inv/v1/tags/getTags?forTableDisplay=${forTableDisplay}`,
			method: 'GET',
			header: buildHeader(idInventory, idInventoryFb)
		};
	},
	insertTag: (idInventory: string, idInventoryFb: string | null, name: string, added: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/tags/insertTag`,
		method: 'POST',
		body: { name, added },
		header: buildHeader(idInventory, idInventoryFb)
	}),
	updateTag: (idNomTag: string, idNomTagFb: string | undefined, idInventory: string, idInventoryFb: string | null
						, name: string, modified: string): IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/tags/updateTag`,
		method: 'PUT',
		body: { idNomTag, idNomTagFb, name, modified },
		header: buildHeader(idInventory, idInventoryFb)
	}),

	deleteTag: (idInventory: string, idInventoryFb: string | null, idNomTag: string, idNomTagFb: string | undefined):IEndpoint => ({
		url: `${API_BASE_URL}/private-inv/v1/tags/deleteTag`,
		method: 'DELETE',
		body: { idNomTag, idNomTagFb },
		header: buildHeader(idInventory, idInventoryFb)
	}),



	getUserInfo: (): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private/v1/users/getUserInfo`,
			method: 'GET'
		};
	},

	getLicensePrices: (): IEndpoint => {
		return {
			url: `${API_BASE_URL}/public/v1/licenses/getPrices`,
			method: 'GET'
		};
	},
	getAssignedLicenses: (): IEndpoint => {
		return {
			url: `${API_BASE_URL}/private/v1/licenses/getAssignedLicenses`,
			method: 'GET'
		};
	},

	startCustomerPortalSession: (email: string): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/licenses/stripe-customer-portal`,
		method: 'POST',
        body: { email }
    }), 
	
 

	assignLicense: (email: string, role: string, premium: boolean | null, sync: boolean | null): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/licenses/assignLicense`,
		method: 'PUT',
        body: { email, role, premium, sync }
    }),
    removeLicense: (email: string, premium: boolean | null, sync: boolean | null): IEndpoint => ({
        url: `${API_BASE_URL}/private/v1/licenses/removeLicense`,
		method: 'PUT',
        body: { email, premium, sync } 
    }),

	resetPassword: (email: string):IEndpoint => {
		return {
			url: `${API_BASE_URL}/public/v1/resetPassword&email=${email}`,
			method: 'GET'
		};
	},


	/* 
		Auth 
	*/
 
	// addUserSession is public. the user is not completly logged in yet
	addUserSession: (uid: string): IEndpoint => ({
		url: `${API_BASE_URL}/public/v1/users/addUserSession`,
		method: 'PUT',
		body: { uid }
	}),

	removeAllUserSessionsExceptCurrent: (sessionId:string): IEndpoint => ({
		url: `${API_BASE_URL}/private/v1/users/removeAllUserSessionsExceptCurrent`,
		method: 'PATCH',
		body: { sessionId } 
	}),

	logout: (sessionId:string): IEndpoint => ({
		url: `${API_BASE_URL}/private/v1/users/logout`,
		method: 'PATCH',
		body: { sessionId } 
	}),



	uploadSpreadsheet: (formData: FormData): IEndpoint => ({
		url: `${API_BASE_URL}/public/v1/uploads/uploadSpreadsheet`,
		method: 'POST',
		body: formData,
	}),


	mapFields: (systemFields: any[], excelFields: [], isPayingUser: boolean): IEndpoint => {
		return {
			url: `${API_BASE_URL}/public/v1/ai/mapFields?systemFields=${systemFields}&excelFields=${excelFields}&isPayingUser=${isPayingUser}`,
			method: 'GET'
		};
	},



};


function buildHeader(idInventory:string|null, idInventoryFb: string|null) {
    const header:any = {};
    if (idInventory !== null) {
        header['idinventory'] = idInventory;
    }
    if (idInventoryFb !== null) {
        header['idinventoryfb'] = idInventoryFb;
    }
    return header;
}


 

export const sendRequest = async <T>(endpoint:IEndpoint): Promise<T> => {
	const { url, method, body, header } = endpoint; 


	const loggedInUserStr = localStorage.getItem('logged_in_user');
	const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr) : null;

	let authToken = loggedInUser?.authToken;

	// console.log(url)
	// console.log(loggedInUser)
	
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const finalContentType = body instanceof FormData ? undefined : "application/json";

	const commonHeaders = {
		Language: i18n.language,
		'Content-Type': finalContentType,
		'Timezone': timezone,
	};
	
	const authorizationHeaderValue = authToken
		? `Bearer ${authToken}`
		: `h ${process.env.REACT_APP_API_KEY}`;
	

	const headers = {
		...commonHeaders,
		...header,
		Authorization: authorizationHeaderValue,
	};
  
	let finalHeaders = headers;
	if (body instanceof FormData) {
		// Create a new headers object without the Content-Type property
		 // Let the browser set the content type for FormData with the correct boundary
		finalHeaders = new Headers(headers);
		finalHeaders.delete('Content-Type');
	}


	let response = await fetch(url, { 
		method,
		headers: finalHeaders,
   		body: body instanceof FormData ? body : (body ? JSON.stringify(body) : null)
	});

	// Check if the response indicates token expiry
	if (!response.ok && response.status === 401) { 

		console.log('Token expired. Refreshing...');

		// Refresh the token
		await refreshFirebaseToken();


		// Update the authToken and headers with the new token
		const updatedLoggedInUserStr = localStorage.getItem('logged_in_user');
		const updatedLoggedInUser = updatedLoggedInUserStr ? JSON.parse(updatedLoggedInUserStr) : null;
		authToken = updatedLoggedInUser?.authToken; 

		headers.Authorization = `Bearer ${authToken}`;

		// Retry the request with the new token
		console.log('Retrying request with new token...');
		console.log("url",url);
		console.log("headers",headers);

		response = await fetch(url, {
			method,
			headers,
			body: body ? JSON.stringify(body) : null
		});

		// refresh the userInfo
		await sendRequest(endpoints.getUserInfo());

		// force refresh the whole page
		window.location.reload();
	}

	if (!response.ok) {
		throw await response.json();
	}  
 
	const data = await response.json();
	return data;
}; 

